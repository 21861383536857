import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000',
    background: '#fff',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
    height: '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h1: {
    display: 'inline-block',
    borderRight: '1px solid rgba(0, 0, 0,.3)',
    margin: '0',
    marginRight: '20px',
    padding: '10px 23px 10px 0',
    fontSize: '24px',
    fontWeight: 500,
    verticalAlign: 'top',
  },
  h2Wrapper: {
    display: 'inline-block',
    textAlign: 'left',
    lineHeight: '49px',
    height: '49px',
    verticalAlign: 'middle',
  },
  h2: {
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: 'inherit',
    margin: '0',
    padding: '0',
  },
}))

const IS_IN_BROWSER = typeof window !== 'undefined'

export default function Four0Four(): ReactElement {
  const classes = useStyles()
  return (
    IS_IN_BROWSER && (
      <div className={classes.root}>
        <div>
          <h1 className={classes.h1}>404</h1>
          <div className={classes.h2Wrapper}>
            <h2 className={classes.h2}>This page could not be found.</h2>
          </div>
        </div>
      </div>
    )
  )
}
